import { Col, Row } from 'antd'
import Explore from 'components/Explore'
import { Styled3ColsWrapper } from 'components/GlobalStyles'
import MediumContainer from 'components/MediumContainer'
import MobileConfirmationModal from 'components/Modals/MobileConfirmation'
import ProductDefaultMain from 'components/ProductDefaultMain'
import ProductsLoader from 'components/ProductsLoader'
import Project350 from 'components/Project350/CatalogBanner/index'
import { Banner3, Banner4, DeliveryBanner } from 'components/PromoBanner'
import Link from 'next/link'
import { IS_BONANZA_PROMO_ACTIVE } from 'utils/constants'
import BonanzaPrompt from './BonanzaPrompt'
import ViewContainer from './container'
import { Styled, StyledH2 } from './styles'

export default function Index({ ...pageProps }) {
  return (
    <ViewContainer {...pageProps}>
      {({
        state,
        featuredProducts,
        verifyMobile,
        setVerifyMobile,
        showBonanzaPrompt,
        closeBonanzaPrompt
      }) => (
        <>

          {/* Mobile Verification */}
          <MobileConfirmationModal
            visible={verifyMobile}
            footer={null}
            setVisible={setVerifyMobile}
          />
          <MediumContainer>

            {IS_BONANZA_PROMO_ACTIVE && (
            <BonanzaPrompt
              visible={showBonanzaPrompt}
              closeModal={closeBonanzaPrompt}
            />
            )}

            {IS_BONANZA_PROMO_ACTIVE && (
              <Row style={{ marginBottom: '30px', marginTop: '60px' }}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                >
                  <Project350 />
                </Col>
              </Row>
            )}
            <Row style={{ marginBottom: '30px', marginTop: '60px' }}>
              <Col
                xs={{ span: 18 }}
                sm={{ span: 18 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <StyledH2>Featured Products</StyledH2>
              </Col>
              <Col
                style={{ marginTop: 10, textAlign: 'right' }}
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
              >
                <Link href="/catalog/categories/more">VIEW MENU</Link>
              </Col>
            </Row>
            <Styled>
              {state && state.featuredProducts.status === 1 && (
                <Row>
                  <ProductsLoader isFeatured={true} />
                </Row>
              )}
              {state.featuredProducts.status === 2 && (
                <Row>
                  <Col span={24}>
                    <Styled3ColsWrapper>
                      {featuredProducts.length > 0 &&
                        featuredProducts.map((featuredProduct) => (
                          <ProductDefaultMain
                            key={featuredProduct.id}
                            product={featuredProduct.product_data}
                          />
                        ))}
                    </Styled3ColsWrapper>
                  </Col>
                </Row>
              )}
            </Styled>
          </MediumContainer>
          <Banner3 />
          <Banner4 />
          <MediumContainer>
            <Col
              xs={{ span: 18 }}
              sm={{ span: 18 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <StyledH2>Explore More</StyledH2>
            </Col>
            <Explore />
            <br />
          </MediumContainer>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <DeliveryBanner />
          </Col>

        </>
      )}
    </ViewContainer>
  )
}
