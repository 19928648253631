import { Image, Modal } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PROJECT350_URL } from 'utils/constants'

const StyledModal = styled(Modal)`
  .ant-modal-body,
  .ant-modal-content {
    // background-image: url('/images/project-350/project-350-modal-bg.png');
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 35px;
    padding: 0 !important;
    position: relative;
  padding: 0 !important;

  }

  .ant-modal-close-x {
    color: #fff;
  }

  .header-wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .ant-row {
    padding: 24px;
    text-align: center;
  }

  .ant-btn {
    background: #fff;
    color: #bb0706;
    font-weight: bold;
  }

  @media (min-width: 376px) and (max-width: 576px) {
    width: 325px !important;
    margin: 0 !important;
  }
`

export default function BonanzaPrompt({ visible, closeModal }) {
  return (
    <StyledModal
      centered
      visible={visible}
      footer={null}
      width='auto'
      onCancel={closeModal}
      maskClosable={false}
    >
      <Image src="/images/project-350/modal-final.png" alt="Bonanza 50K Banner" preview={false} style={{ width: '100%', height: '80vh', objectFit: 'contain' }} draggable={false} />
      <button onClick={() => {
        window.location.href = PROJECT350_URL
      }} style={{ position: 'absolute', bottom: 75, left: 0, right: 0, padding: '50px', textAlign: 'center', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', zIndex: 10, width: '70%', margin: '0 auto' }}>

      </button>
      {/* <div style={{ position: 'relative' }}>
        <Image
          src="/images/project-350/top-border.png"
          alt="Bonanza 50K Banner"
          preview={false}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderTopLeftRadius: 35,
            marginBottom: '-20px'
          }}
          draggable={false}
        />

        <div className="header-wrapper" style={{ paddingTop: '10px' }}>
          <Image
            src="/images/project-350/sc-logo-white.png"
            alt="Bonanza 50K Banner"
            preview={false}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              margin: '0 auto',
              marginBottom: '-30px'
            }}
            draggable={false}
          />

          <Image
            src="/images/project-350/50k-modal-banner.png"
            alt="Bonanza 50K Banner"
            preview={false}
            className="header-banner"
            style={{ width: '80%', height: 'auto', objectFit: 'contain', margin: '0 auto' }}
            draggable={false}
          />
        </div>
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Col span={24}>
            <div style={{ marginTop: '-50px' }}>
              <Image
                src="/images/project-350/cash-prize-banner.png"
                alt="Shakeys 50 Years Anniversary Cash Prize"
                preview={false}
                style={{ width: '100%', height: 'auto', maxWidth: '474px' }}
              />

              <Image
                src="/images/project-350/brands.png"
                alt="Shakeys 50 Years Anniversary Cash Prize"
                preview={false}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '200px',
                  margin: '0 auto',
                  marginTop: '-5px'
                }}
                draggable={false}
              />
            </div>
          </Col>
          <Col xs={24} md={14}>

          </Col>
          <Col span={24}>
            <Button
              onClick={() => {
                closeModal()
                window.location.href = PROJECT350_URL
              }}
              type="ghost"
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <Image
                src="/images/project-350/flare-btn.png"
                alt="Bonanza Flare Button"
                preview={false}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '292px',
                  margin: '-20px auto 0 auto'
                }}
                draggable={false}
              />
            </Button>
          </Col>
          <Col span={24} style={{ marginTop: '-30px' }}>
            <Image
              src="/images/project-350/50years-logo.png"
              alt="Shakeys 50 Years Anniversary Cash Prize"
              preview={false}
              style={{ width: '100%', height: 'auto', maxWidth: '474px' }}
            />
          </Col>
        </Row>
        <Image
          src="/images/project-350/bottom-border.png"
          alt="Bonanza 50K Banner"
          preview={false}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderBottomRightRadius: 35,
            position: 'static',
            bottom: '100px',
            right: 0,
            margin: 0,
            marginTop: '-10px',
            display: 'inline-block'
          }}
          draggable={false}
        />
      </div> */}
    </StyledModal>
  )
}

BonanzaPrompt.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func
}
